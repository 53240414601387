import axios, { AxiosResponse } from "axios";
import { Auth } from "hooks/useAuth";
import { MidiType } from "utils/types/enums/midiVar";
import type {
  GeneratedNames,
  GeneratedSynthPath,
  Task,
} from "types/result.types";
import type { SelectedSf, TagSetIn, TagSetSettings } from "types/tags.types";
import { errorCatch } from "./error";
import { keys } from "utils/constants/presets.const";

const url = `${process.env.REACT_APP_BACKEND_API_URL}`;

export async function generateRithm(
  params: TagSetIn,
  synth_preset: string | null,
  percussion: string,
  auth: Auth,
  sfInfo: SelectedSf,
  is_separated: boolean
): Promise<Task | string> {
  try {
    const fileSf = await axios.get<Blob>(sfInfo.url_sf, {
      responseType: "blob",
    });
    const fileSfBlob = fileSf.data;

    const formData = new FormData();
    formData.append("sf_file", fileSfBlob, sfInfo.name);
    formData.append("tags", JSON.stringify(params));

    const synth_params: TagSetIn = {
      key: keys[0].name,
      bpm: 0,
      genre_id: 0,
      preset: synth_preset?.toLowerCase() || "small_synth_1",
    };
    formData.append("synth_params", JSON.stringify(synth_params));

    formData.append("percussion", JSON.stringify(percussion));

    const response: AxiosResponse<Task> = await axios.post(
      `${url}/generate/file`,
      formData,
      {
        auth: {
          username: auth.username,
          password: auth.password,
        },
        params: {
          is_separated: is_separated,
        },
      }
    );
    return response.data;
  } catch (error) {
    const errorMessage = errorCatch(error);
    console.error(error);
    return errorMessage;
  }
}

export async function generateSynth(
  preset: string | null,
  auth: Auth
): Promise<GeneratedNames | string> {
  const newParams: TagSetIn = {
    // key_id: 0,
    // tonality_id: 0,
    key: keys[0].name,
    bpm: 0,
    genre_id: 0,
    preset: preset,
  };
  try {
    const response: AxiosResponse<string> = await axios.post(
      `${url}/generate/synth/file`,
      newParams,
      {
        auth: {
          username: auth.username,
          password: auth.password,
        },
      }
    );
    return response.data;
  } catch (error) {
    const errorMessage = errorCatch(error);
    console.error(error);
    return errorMessage;
  }
}

export async function generateBass(
  tagSetSettings: TagSetSettings,
  preset: string,
  auth: Auth
): Promise<GeneratedSynthPath | string> {
  const params: TagSetIn = {
    key: tagSetSettings.key,
    bpm: tagSetSettings.bpm,
    genre_id: tagSetSettings.genre_id,
    preset: preset,
  };
  try {
    const response: AxiosResponse<string> = await axios.post(
      `${url}/generate/bass/file`,
      params,
      {
        auth: {
          username: auth.username,
          password: auth.password,
        },
      }
    );
    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
}

export async function fetchGeneratedBassFile(
  result_id: string,
  filename: string,
  auth: Auth
) {
  try {
    const name = MidiType.STANDART + "_" + filename.slice(0, -4);
    const response: AxiosResponse<Blob> = await axios.get(
      `${url}/results/gen/${MidiType.STANDART}/${result_id}/${name}`,
      {
        responseType: "blob",
        auth: {
          username: auth.username,
          password: auth.password,
        },
      }
    );
    const resUrl = URL.createObjectURL(response.data);
    return resUrl;
  } catch (error) {
    console.error(errorCatch(error));
  }
}

export const fetchArchive = async (
  auth: Auth,
  type: string,
  result_id: string,
  typeOfGeneration: string
) => {
  try {
    const genUrl = ["synth", "bass"].includes(typeOfGeneration)
      ? `${url}/results/${typeOfGeneration}/gen/${result_id}`
      : `${url}/results/gen/${type}/${result_id}/archive`;

    const response = await axios.get(genUrl, {
      responseType: "arraybuffer",
      auth: {
        username: auth.username,
        password: auth.password,
      },
    });
    return response.data;
  } catch (error) {
    console.error(errorCatch(error));
  }
};

export const fetchMidiArchive = async (auth: Auth, result_id: string) => {
  try {
    const response = await axios.get(`${url}/results/wav/gen/${result_id}`, {
      responseType: "arraybuffer",
      auth: {
        username: auth.username,
        password: auth.password,
      },
    });
    return response.data;
  } catch (error) {
    console.error(errorCatch(error));
  }
};

import { Checkbox, FormControlLabel, InputAdornment, MenuItem, OutlinedInput, Paper, Select, Stack, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { SectionForm } from '../../ui/form/SectionForm'
import { AppState } from 'index';
import useRithmSectionSettings from 'hooks/useRithmSectionSettings';
import { BaseInput } from '../tags/BaseInput';
import { ButtonGenerate } from 'components/buttons/ButtonGenerate';
import { DownloadArchiveButton } from 'components/buttons/DownloadArchiveButton';
import { SelectSfInput } from './SelectSfInput';
import { TagNumberInput } from '../tags/TagNumberInput';
import { TagSelect } from '../../ui/inputs/TagSelect';
import { keys } from 'utils/constants/presets.const';
import { TagSetSettings, TagStr } from 'types';

export function RithmSection() {
    const state = useContext(AppState);
    const {
        handleChangeBassPreset, 
        handleChangeBpm, 
        handleChangeKey,
        handleChangeForm,
        handleChangeSeparated
    } = useRithmSectionSettings();
    const base_bass_presets: TagStr[] = state.bass_presets.value.slice(0, 3)

  return (
    <SectionForm
        header='Генерация ритм секции'
    >
        <BaseInput name='Preset' idDisabled={false}>
            <Select 
                value={state.selectedTagSetSettings.value.preset.toString() || ''}
                onChange={handleChangeBassPreset}
            >
                <MenuItem key={0} value={0}>Не выбран</MenuItem>
                {
                    base_bass_presets.map((tag) => (
                        <MenuItem key={tag.id} value={tag.id}>{tag.name}</MenuItem>
                    ))
                }
            </Select>
        </BaseInput>

        <BaseInput name='Тональность' idDisabled={true}>
            {/* <Stack direction={"row"} spacing={1}>
                <Select value={"0"}>
                    <MenuItem key={0} value={0}>C</MenuItem>
                </Select>
                <Select
                    value="0"
                >
                    <MenuItem key={0} value={0}>мажор</MenuItem>
                </Select>
            </Stack> */}
            <TagSelect 
                tagList={keys}
                handleChangeTag={handleChangeKey}
                selectedTag={keys.find((tag) => tag.name === state.selectedTagSetSettings.value.key)?.id || 0}
                nameForm="Тональность"
            />
        </BaseInput>

        <BaseInput name='Темп' idDisabled={false}>
            <TagNumberInput selectedNumber={state.selectedTagSetSettings.value.bpm} handleChangeNumber={handleChangeBpm} name='bpm'/>
            {/* <OutlinedInput value="120" endAdornment={<InputAdornment position="end">bpm</InputAdornment>}/> */}
        </BaseInput>

        <BaseInput name='Жанр' idDisabled={true}>
            <Select
                value="0"
            >
                <MenuItem key={0} value={0}>Tech house</MenuItem>
            </Select>
        </BaseInput>

        <BaseInput name='Форма' idDisabled={false}>
            {/* <TagSelect
                tagList={state.formNames.value.map((name, idx) => ({id: idx, name: name}))}
                handleChangeTag={() => {}}

            /> */}
            <Select 
                value={state.selectedTagSetSettings.value.form_idx.toString() || ''}
                onChange={handleChangeForm}
            >
                {
                    state.formNames.value.map((name, idx) => (
                        <MenuItem key={idx} value={idx}>{name}</MenuItem>
                    ))
                }
            </Select>
        </BaseInput>

        <BaseInput name="" idDisabled={false}>
            <FormControlLabel
                sx={{alignSelf: 'flex-start'}}
                control={
                    <Checkbox 
                        checked={state.is_separated.value}
                        onChange={handleChangeSeparated}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                }
                label="separated"
            />
        </BaseInput>

        <SelectSfInput/>
        
        <ButtonGenerate variant='bass'/>

        {
            state.generatedNames.value.result_id !== '' && state.generatedNames.value.midi_files.length > 0 ? (
                <Stack direction='column' spacing={1}>
                    <DownloadArchiveButton variant='rhithm' format='midi'/>
                    <DownloadArchiveButton variant='rhithm' format='wav'/>
                </Stack>
            ) : null
        }
    </SectionForm>
  )
}

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { AppState } from "index";
import { generateRithm, generateSynth } from "api/generations.api";
import axios, { AxiosProgressEvent, AxiosResponse } from "axios";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { LoadStatus } from "utils/hooks/enums/LoadStatus";
import { Router } from "utils/routes/routes";
import useAuth from "hooks/useAuth";
import { TagSetIn, TagSynthSettings } from "types/tags.types";
import { GeneratedNames, Task } from "types/result.types";
import { getModeForResolutionAtIndex } from "typescript";

interface ButtonGenerateProps {
  variant: "bass" | "synth";
}

export function ButtonGenerate(props: ButtonGenerateProps) {
  const state = useContext(AppState);
  const { auth } = useAuth();

  const [resultId, setResultId] = React.useState<string>("");
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [errorMessage, setErrorMesage] = React.useState("");
  const [isError, setIsError] = React.useState(false);

  const checkTask = async (task_id: string) => {
    try {
      const response: AxiosResponse<GeneratedNames> = await axios.get(
        `${process.env.REACT_APP_BACKEND_API_URL}/results/task/`,
        {
          params: {
            task_id: task_id,
          },
          auth: {
            username: auth.username,
            password: auth.password,
          },
        }
      );
      console.log(response);
      if (!response.data) {
        console.log("!response.data");
        setIsLoaded(true);
        return;
      }
      if (response.data.result_id) {
        state.generatedNames.value = response.data;
        setResultId(response.data.result_id);
        try {
          if (response.data.midi_files.length === 0) {
            setIsError(true);
            setErrorMesage("Генерация не удалась. Попробуйте еще раз.");
          }
        } catch {}
        setIsLoaded(false);
        return true;
      }
    } catch (error: any) {
      console.error(error);
      setErrorMesage(`${error?.message}: ${error?.response?.data?.detail}`);
      setIsError(true);
      setIsLoaded(false);
      return true;
    }
    return false;
  };

  const getResult = async (task_id: string) => {
    const res = await checkTask(task_id);
    if (res) return;

    setTimeout(() => {
      getResult(task_id);
    }, 5000);
  };

  const handleGenerateStart = async () => {
    setIsLoaded(true);
    setIsError(false);
    state.generatedNames.value = {
      result_id: "",
      midi_files: [],
    };

    let result: GeneratedNames | string = "";
    let response: string | Task = "";

    if (props.variant === "bass") {
      const params: TagSetIn = {
        key: state.selectedTagSetSettings.value.key,
        bpm:
          state.selectedTagSetSettings.value.bpm === 0
            ? 120
            : state.selectedTagSetSettings.value.bpm,
        genre_id: state.selectedTagSetSettings.value.genre_id,
        preset:
          state.bass_presets.value
            .find((tag) => tag.id === state.selectedTagSetSettings.value.preset)
            ?.name.toLowerCase() || "complex_bass",
        form_name:
          state.formNames.value[state.selectedTagSetSettings.value.form_idx] ||
          state.formNames.value[0],
      };
      const synth_preset =
        state.synth_presets.value.find(
          (tag) => tag.id === state.selectedTagSynthSettings.value.preset
        )?.name || null;
      const percussion = state.selectedPercussion.value;
      const is_separated = state.is_separated.value;
      // task name
      response = await generateRithm(
        params,
        synth_preset,
        percussion.name,
        auth,
        state.selectedSf.value,
        is_separated
      );
    } else {
      // result
      const presetName =
        state.synth_presets.value.find(
          (tag) => tag.id === state.selectedTagSynthSettings.value.preset
        )?.name || null;
      result = await generateSynth(presetName, auth);
    }

    if (props.variant === "bass") {
      if (typeof response !== "string") {
        await getResult(response.task_id);
      } else {
        setErrorMesage(response);
        setIsError(true);
      }
    } else {
      if (typeof result !== "string") {
        state.generatedSynthPath.value = result;
        setResultId(result.result_id);
        try {
          if (result.midi_files.length === 0) {
            setIsError(true);
            setErrorMesage("Генерация не удалась. Попробуйте еще раз.");
          }
        } catch {}
      }
      setIsLoaded(false);
    }
  };

  return (
    <Stack direction={"column"}>
      <Button
        disabled={isLoaded}
        variant="contained"
        title="Сгенерировать"
        onClick={handleGenerateStart}
      >
        {isLoaded ? (
          <Stack direction="row" spacing={1} alignItems="center">
            <CircularProgress />
            <Typography>Loading...</Typography>
          </Stack>
        ) : (
          "Сгенерировать"
        )}
      </Button>
      {/* </Link> */}
      {isError ? <Alert severity="error">{errorMessage}</Alert> : null}
    </Stack>
  );
}

import { SelectChangeEvent } from "@mui/material";
import { AppState } from "index";
import { useContext, useState } from "react";
import { keys } from "utils/constants/presets.const";
export default function useRithmSectionSettings() {
    const state = useContext(AppState);
    const [genreId, setGenreId] = useState<number>(0);


    const handleChangeForm = (e: SelectChangeEvent) => {
        state.selectedTagSetSettings.value = {
            ...state.selectedTagSetSettings.value,
            form_idx: Number(e.target.value),
        }
    }


    const handleChangeSeparated = (event: React.ChangeEvent<HTMLInputElement>) => {
        state.is_separated.value = event.target.checked;
    }

    const handleChangeGenre = (e: SelectChangeEvent) => {
        const seldTag = state.genreTags.value.find(tag => tag.id === Number(e.target.value));

        state.selectedTagSetSettings.value = {
            ...state.selectedTagSetSettings.value,
            genre_id: seldTag ? seldTag.id : 0,
        }

        setGenreId(Number(e.target.value));
    }

    const handleChangeKey = (e: SelectChangeEvent) => {
        // const seldTag = state.keyTags.value.find(tag => tag.id === Number(e.target.value));
        const seldTag = keys.find(tag => tag.id === Number(e.target.value));

        state.selectedTagSetSettings.value = {
            ...state.selectedTagSetSettings.value,
            key: seldTag ? seldTag.name : keys[0].name,
        }

        // state.selectedTagSetSettings.value = {
        //     ...state.selectedTagSetSettings.value,
        //     key_id: seldTag ? seldTag.id : 0,
        // }
    }

    const handleChangeBpm = (e: React.ChangeEvent<HTMLInputElement>) => {
        state.selectedTagSetSettings.value = {
            ...state.selectedTagSetSettings.value,
            bpm: Number(e.target.value)
        }
    }

    const handleChangeBassPreset = (e: SelectChangeEvent) => {
        const seldPBassPreset = state.bass_presets.value.find(tag => tag.id === Number(e.target.value));

        state.selectedTagSetSettings.value = {
            ...state.selectedTagSetSettings.value,
            preset: seldPBassPreset ? seldPBassPreset.id : 1
        }
    }

    const handleChangeSelectedSf  = (e: React.ChangeEvent<HTMLInputElement>) => {
        try {
            if (e.target.files) {
                const sfUrl = URL.createObjectURL(e.target.files[0])
                state.selectedSf.value = {
                    name: e.target.files[0].name,
                    url_sf: sfUrl
                }
            }
        } catch {
            state.selectedSf.value = {
                ...state.selectedSf.value
            }
        }

    }

    const handleDeleteSf = () => {
        state.selectedSf.value = {
            name: '',
            url_sf: ''
        }
    }

    return {
        handleChangeBassPreset,
        handleChangeSelectedSf,
        handleDeleteSf,
        handleChangeBpm,
        handleChangeKey,
        handleChangeForm,
        handleChangeSeparated,
    }
}